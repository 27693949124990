import React, { useState, useEffect } from 'react';
import styles from './LayerMenu.module.css';
import {Box, IconButton } from '@material-ui/core';
import ViewListIcon from '@material-ui/icons/ViewList';
import { getAuthorizationData, refreshToken, getTokenIfValidAndNotExpired, fetchWithAuthorisationHeader, postWithAuthorisationHeader } from "../../../services/AuthenticationService";
import { IExtent } from '../../../models/IExtent';
import LocationSearchingIcon from '@material-ui/icons/LocationSearching';
import { I3DLayer } from '../../../models/I3DLayer';
import { IMenuLayer } from '../../../models/IMenuLayer';
import { I3DTile } from '../../../models/I3DTile';
import { ClassificationList } from '../ClassificationList/ClassificationList';
import { I3DClassificationClasses } from '../../../models/I3DClassification';
import { CesiumViewerWrapper } from '../CesiumViewerWrapper/CesiumViewerWrapper';

interface ILayerMenuProps {
    cesiumViewerWrapper: CesiumViewerWrapper;
    canLoad3DTiles: boolean;
    cameraExtent: IExtent | null;
    apiUrl: string;
    onSplitterToggleChange: Function;
    splitterPosition: number;
    containerHeaderSize?: number;
    onLayerMenuChange: Function;
    onClassificationSelectionChange: Function;
    layerMenu: IMenuLayer[] | null;
    classificationSelection: I3DClassificationClasses[] | null;
}
interface IThreeDTileCoverage {
    threeDTileCoverageId: number;
    dateCreated: string;
    tilesetJsonKey: string;
    bucket: string;
    threeDTileLayerId: number;
}

const LayerMenu = (props: ILayerMenuProps) => {

    const POINT_SIZE = 3.0;

    let Cesium = (window as any).Cesium;

    const [layerList, setLayerList] = useState<I3DLayer[]>([]);
    const [displayLayers, setDisplayLayers] = useState<boolean>(false);
    const [allLayersVisible, setAllLayersVisible] = useState<boolean>(false);
    const [classificationClasses, setClassificationClasses] = useState<I3DClassificationClasses[]>([]);
    const [isSplitterReady, setIsSplitterReady] = useState<boolean>(false);
    const [isSplitScreen, setIsSplitScreen] = useState<boolean>(false);
    const [topOffset, setTopOffset] = useState(-23); 
    const [isLayerMenuInitialised, setIsLayerMenuInitialised] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);


    useEffect(() => {
        getThreeDTileLayers();
    }, []);

    useEffect(() => {
        displaySplitter(isSplitScreen)        
    }, [isSplitScreen]);

    useEffect(() => {
        if (props.splitterPosition > 0) setIsSplitScreen(true);
        else setIsSplitScreen(false);
    }, [props.splitterPosition]);
  
    useEffect(() => {
        // We want to get tiles only if the extent have changed
        console.log('Extent changed ... ' + props.cameraExtent);
        if (props.cameraExtent != null) {
            getThreeDTiles(layerList.map(layer => layer.threeDTileLayerId), props.cameraExtent); // Get coverage tiles for all layers
        }
    }, [props.cameraExtent]);

    useEffect(() => {
        if (props.containerHeaderSize) {
            setTopOffset(props.containerHeaderSize - 23);
        }
    }, [props.containerHeaderSize]);

    // Signal the threeDViewer layer selection have changed
    useEffect(() => {
        let menuLayer = layerList.map(layer => ({ threeDTileLayerId: layer.threeDTileLayerId, defaultVisibility : layer.defaultVisibility}));
        props.onLayerMenuChange(menuLayer);

        // Update the visibility from the prop out state after the layers are created.
        if (props.layerMenu !== null && !isLayerMenuInitialised) { // Only want to do this once to synch pop out state 
            let isAllVisible = true;
            // layerMenu updated from pop out state
            props.layerMenu.forEach((l: IMenuLayer) => {
                const layer = layerList.find(({ threeDTileLayerId }) => threeDTileLayerId === l.threeDTileLayerId);
                if (layer !== undefined) {
                    layer.defaultVisibility = l.defaultVisibility;
                    if (l.defaultVisibility === false) isAllVisible = false;
                }
            });

            setLayerList(layerList);
            setAllLayersVisible(isAllVisible);
            
            if (props.classificationSelection !== null) {
                setClassificationClasses(props.classificationSelection);
                layerList.forEach((layer: I3DLayer) => {
                    if (props.classificationSelection !== null) updateClassificationStyle(layer, props.classificationSelection);
                });
            }

            // Set to true so we don't try to synch the state again betweeen the pop out viewer and the iframe
            setIsLayerMenuInitialised(true);
        }
    }, [layerList]);

    // Signal to threeDViewer classification have changed
    useEffect(() => {
        props.onClassificationSelectionChange(classificationClasses);
    }, [classificationClasses]);




    //Fetch 3d tile layers
    async function getThreeDTileLayers()
    {
        try {
            let url = props.apiUrl + "threedmodel/tile/layers";
            let response = await fetchWithAuthorisationHeader(url);

            if (response && response.data) {
                var layers = response.data as I3DLayer[];

                layers.forEach((l: I3DLayer) => {
                    l.classificationFile = l.classificationFile && JSON.parse(l.classificationFile);
                });
               
                setLayerList(layers);
                setAllLayersVisible(layers.every((layer: I3DLayer) => layer.defaultVisibility));
            }
        }
        catch (error) {
            console.error('Error fetching 3D tile layers:', error);
        }
    }
    async function displaySplitter(isOn:boolean) {
        // Setup slider for 3d Tile comparision
        const slider = document.getElementById("slider");

        if (slider != null && slider.parentElement != null) {
            if (isOn) {
                if (!isSplitterReady) {
                    setupSplitter();                    
                }
                else {                    
                    slider.style.display = 'Block';
                    setIsSplitterReady(false);
                }
            }
            else {
                // Split already setup. Just toggle display
                props.onSplitterToggleChange(0); // Signal the the splitter is off when popped out
                slider.style.display = 'none';
                setIsSplitterReady(false);
            }

            // Update existing 3d tiles 
            update3DtileSplitterStatus(isOn);

            if (props.cesiumViewerWrapper.scene !== undefined ) props.cesiumViewerWrapper.scene.requestRender();
        }
    }

    // Set the split direction of loaded tiles based on the splitter setting
    async function update3DtileSplitterStatus(isSplitOn: boolean) {
        // Update the split direction of all loaded tiles.
        layerList.forEach((l: I3DLayer) => {
            if (l.tiles !== undefined) {
                l.tiles.forEach((t: I3DTile) => {
                    setTileSplitDirection(isSplitOn, l, t.tilesetRef);
                });
            }
        });

        if (props.cesiumViewerWrapper.scene !== undefined) props.cesiumViewerWrapper.scene.requestRender();
        setLayerList(layerList);
    }

    async function setTileSplitDirection(isSplitOn: boolean, layer: I3DLayer, cesiumTileset: any) {
        if (cesiumTileset !== undefined) {
            if (isSplitOn) {
                switch (layer.splitDirection) {
                    case "NONE":
                        cesiumTileset.splitDirection = Cesium.SplitDirection.NONE;
                        break;
                    case "LEFT":
                        cesiumTileset.splitDirection = Cesium.SplitDirection.LEFT;
                        break;
                    case "RIGHT":
                        cesiumTileset.splitDirection = Cesium.SplitDirection.RIGHT;
                        break;
                }
            }
            else {
                cesiumTileset.splitDirection = Cesium.SplitDirection.NONE;
            }
        }
    }

    async function setupSplitter() {
        // Setup slider for 3d Tile comparision
        const slider = document.getElementById("slider");
        let moveActive = false;

        if (slider != null && slider.parentElement != null) {

            //slider.style.display  = 'none';
            //slider.style.right  = '99%';
            slider.style.position = "absolute";
            slider.style.right = props.splitterPosition > 0?100 - (props.splitterPosition * 100) + "%":"50%";
            slider.style.top = "0px";
            slider.style.backgroundColor = "#d3d3d3";
            slider.style.width = "5px";
            slider.style.zIndex = "9999";            
            slider.style.cursor = "ew-resize";
            slider.style.display = 'block';

            if (props.splitterPosition > 0) {
                props.cesiumViewerWrapper.scene.splitPosition = props.splitterPosition; // From property when popped out
            }
            else {
                props.cesiumViewerWrapper.scene.splitPosition = slider.offsetLeft / slider.parentElement.offsetWidth; // Start with middle
            }
            
            const handler = new Cesium.ScreenSpaceEventHandler(slider);

            handler.setInputAction(function () {
                moveActive = true;
            }, Cesium.ScreenSpaceEventType.LEFT_DOWN);
            handler.setInputAction(function () {
                moveActive = true;
            }, Cesium.ScreenSpaceEventType.PINCH_START);

            handler.setInputAction(move, Cesium.ScreenSpaceEventType.MOUSE_MOVE);
            handler.setInputAction(move, Cesium.ScreenSpaceEventType.PINCH_MOVE);

            handler.setInputAction(function () {
                moveActive = false;
            }, Cesium.ScreenSpaceEventType.LEFT_UP);
            handler.setInputAction(function () {
                moveActive = false;
            }, Cesium.ScreenSpaceEventType.PINCH_END);

            if (props.cesiumViewerWrapper.scene !== undefined) props.cesiumViewerWrapper.scene.requestRender();

            props.onSplitterToggleChange(props.cesiumViewerWrapper.scene.splitPosition); // signal the spliiter is on when popped out. i.e. splitterPosition > 0 means it is ON
            setIsSplitterReady(true);            
        }

        function move(movement: any) {
            if (!moveActive) {
                return;
            }
            const relativeOffset = movement.endPosition.x;

            if (slider != null && slider.parentElement != null) {
                let splitPosition = (slider.offsetLeft + relativeOffset) / slider.parentElement.offsetWidth;

                if (splitPosition > 1.00) splitPosition = 1.00;
                if (splitPosition < 0) splitPosition = 0;

                slider.style.left = `${100.0 * splitPosition}%`;

                props.cesiumViewerWrapper.scene.splitPosition = splitPosition;

                // Update parent splitter state
                props.onSplitterToggleChange(splitPosition);

                if (props.cesiumViewerWrapper.scene !== undefined) props.cesiumViewerWrapper.scene.requestRender();
            }
        }
    }

    //Fetch 3d tiles
    async function getThreeDTiles(threeDTileLayerIds: number[], cameraExtent: IExtent) :Promise<any> {

        if (!props.canLoad3DTiles || threeDTileLayerIds.length === 0) {
            return [];
        }

        const cameraExtentWKt = `POLYGON((${cameraExtent.west} ${cameraExtent.south},${cameraExtent.west} ${cameraExtent.north},${cameraExtent.east} ${cameraExtent.north},${cameraExtent.east} ${cameraExtent.south},${cameraExtent.west} ${cameraExtent.south}))`;

        try {
            let url = props.apiUrl + "threedmodel/tile/coverage/";
            let data = {
                "layerIds": threeDTileLayerIds.join(','),
                "extentWKT": cameraExtentWKt
            };

            let response = await postWithAuthorisationHeader(url, data);
            if (response && response.data) {
                //return response.data as IThreeDTileCoverage[];
                var coverageTiles = response.data as IThreeDTileCoverage[];
                await loadTilesInCesium(coverageTiles);
            }

        } catch (error) {
            console.error('Error fetching 3D tiles:', error);
        }

        return [];
    };

    const retryCallback: any = async (resource: any, error: any) => {

        if (error?.statusCode === 401) {

            // Auth issue due to token expiry.  We *might* need to refresh the token
            // As we are potentially loading multiple tiles at the same time we can get a race condtion where they are all trying to refresh the token at almost the same time
            // So first check if we actually do have a valid token from a recent refresh

            var token = getTokenIfValidAndNotExpired();

            if (token == null) {
                // We dont have a valid token so do a refresh
                token = await refreshToken();
            }

            if (token != undefined && resource != undefined) {
                resource.setQueryParameters({ 'access_token': token })
                return true;
            }
        }
        return false
    }

    async function loadTilesInCesium(coverageTiles: IThreeDTileCoverage[]) {
        setIsLoading(true);
        var authData = getAuthorizationData();
        let isTileAdded = false;
        // For each tile from API
        for (const tile of coverageTiles) {   
            // Get the layer for this tile 
            const layer = layerList.find(({ threeDTileLayerId }) => threeDTileLayerId === tile.threeDTileLayerId);

            if (layer != null) {
                if (layer.tiles === undefined) {
                    layer.tiles = [];
                }

                const found = layer.tiles.some(el => el.tileId === tile.threeDTileCoverageId);
                if (!found) {
                    try {
                        // Add the tile to Cesium if not already loaded.
                        const url = props.apiUrl + "threedmodel/modelfile" + (!tile.tilesetJsonKey.startsWith('/') ? '/' : '') + tile.tilesetJsonKey;
                        const resource = new Cesium.Resource({
                            url: url,
                            queryParameters: {
                                'access_token': authData.token
                            },
                            retryCallback: retryCallback,
                            retryAttempts: 1
                        })

                        let tileset = await Cesium.Cesium3DTileset.fromUrl(resource, {
                            shadows: Cesium.ShadowMode.DISABLED,
                            preloadWhenHidden: true,
                            maximumScreenSpaceError: 96, // Default 16 // Thins the point cloud when displaying
                            show: layer.defaultVisibility,
                           
                            // For street-level horizon views
                            dynamicScreenSpaceError: true,
                            dynamicScreenSpaceErrorDensity: 2.0e-4,  // default 2.0e-4
                            dynamicScreenSpaceErrorFactor: 24.0, // default 24.0
                            dynamicScreenSpaceErrorHeightFalloff: 0.25, // default 0.25

                            // Skip tiles to load
                            skipLevelOfDetail: true,
                            baseScreenSpaceError: 1024, // default 1024
                            skipScreenSpaceErrorFactor: 16, // default 16
                            skipLevels: 0, // default 1
                            immediatelyLoadDesiredLevelOfDetail: false, //default false
                            loadSiblings: false, // default false
                            cullWithChildrenBounds: true, // default true
                        });

                        // Set the spitter direction based on the layer setting and splitter check box
                        setTileSplitDirection(isSplitScreen, layer, tileset);

                        
                        var showExpression = layer.showExpression;
                        if (showExpression === undefined) {
                            showExpression = getShowForLayerByClassification(layer, classificationClasses);
                            layer.showExpression = showExpression;                            
                        }
                            

                        var conditions = layer.styleCondition;
                        if (conditions === undefined) {
                            conditions = getStyleCondition(layer);
                            // Store the style condition so we don't have to generate each time
                            layer.styleCondition = conditions;
                        }

                        // Only show non ground points 
                        tileset.style = new Cesium.Cesium3DTileStyle({
                            color: {
                                conditions: conditions,
                            },
                            pointSize: POINT_SIZE,
                            show: showExpression,
                        });

                        setModelHeightOffset(tileset, layer.heightOffset);

                        props.cesiumViewerWrapper.addPrimitiveToScene(tileset)
                        layer.tiles.push({ tileId: tile.threeDTileCoverageId, tilesetRef: tileset }); // Store a reference to the tile
                        isTileAdded = true;
                    }
                    catch (error) {
                        //console.log(error);
                    }
                }
            }
        }

        if (isTileAdded) {
            // Update the state
            setLayerList(layerList);
        }
        setIsLoading(false);
    }

    function getStyleCondition(layer: I3DLayer): string[][] {      

        const condition = [];
        if (layer.classification === "default") condition.push(["true", "color('#ffffff', 0.1)"]);
        else {
            var classifications = layer.classificationFile['classificationLUT'];
            for (const classification of classifications) {
                condition.push(["${Classification} === " + classification.id, "color('" + classification.colour_hex + "', 0.5)"]);       
            }

            condition.push(["true", "color('#ffffff', 0.1)"]);
        }
        return condition;
    }

    function getShowForLayerByClassification(layer: I3DLayer, classificationClasses: I3DClassificationClasses[]): string {
        const showStringParts = [];

        if (layer.classification === "default") return "${Classification} > 2 && ${Classification} !== 8";

        var categories = layer.classificationFile['classificationOverlayCategory'];
        var classifications = layer.classificationFile['classificationLUT'];

        for (const category of categories) {
            var isOn = true;
            if (classificationClasses !== undefined) {
                // Get the display setting for the category from the classification menu
                const layerClass = classificationClasses.find(l => l.threeDTileLayerId === layer.threeDTileLayerId)
                
                if (layerClass !== undefined) {
                    const classCategory = layerClass.classificationCategories.find(c => c.categoryName === category.label);
                    if (classCategory !== undefined) isOn = classCategory.isSelected;
                }
            }
            // Get all the classification for the category
            for (const classification of classifications) {
                if (classification.type === category.label) {
                    // Hide anything with class less than 2. (e.g. ground and noise)
                    if (classification.id > 2 && isOn) showStringParts.push("${Classification} === " + classification.id);
                }
            }
        }

        // When all is 
        if (showStringParts.length === 0) {
            showStringParts.push("${Classification} === 999");
        }

        return showStringParts.join(" || ");
    }
    function setModelHeightOffset(tileset: any, heightOffset: number) {
        const cartographic = Cesium.Cartographic.fromCartesian(
            tileset.boundingSphere.center
        );
        const surface = Cesium.Cartesian3.fromRadians(
            cartographic.longitude,
            cartographic.latitude,
            0.0
        );
        const offset = Cesium.Cartesian3.fromRadians(
            cartographic.longitude,
            cartographic.latitude,
            heightOffset
        );
        const translation = Cesium.Cartesian3.subtract(
            offset,
            surface,
            new Cesium.Cartesian3()
        );
        tileset.modelMatrix = Cesium.Matrix4.fromTranslation(translation);
    }

    function onClickMenu()
    {
        setDisplayLayers(!displayLayers);
    }

    function onSelectAll(e: React.MouseEvent<HTMLAnchorElement>)
    {
        e.preventDefault();
        var threeDTileLayerIds: number[] = [];
        const newVisibleState = !allLayersVisible;
        setLayerList(layerList.map(layer => {
            threeDTileLayerIds.push(layer.threeDTileLayerId);
            let updatedLayer = { ...layer, defaultVisibility: newVisibleState }; // To Do: along with defaultVisibility, update the show property of each tile in tile[] 
            setTileShow(updatedLayer);
            return updatedLayer;
        }));
        setAllLayersVisible(newVisibleState);
    };

    function onLayerChange(threeDTileLayerId: number) {
        setLayerList(layerList.map(layer => {
            if (layer.threeDTileLayerId === threeDTileLayerId) {
                const updatedLayer = { 
                    ...layer, defaultVisibility: !layer.defaultVisibility
                };
                setTileShow(updatedLayer);
                return updatedLayer;
            }
            return layer;
        }));
    };

    function onSplitChange() {
        setIsSplitScreen(!isSplitScreen);
    }

    function setTileShow( layer: I3DLayer) {
        if (layer != null && layer.tiles !== undefined) {
            // Set the show attribute of each layer tile
            for (const tile of layer.tiles) {
                const tileset = tile.tilesetRef;
                tileset.show = layer.defaultVisibility;
            }

            props.cesiumViewerWrapper.scene.requestRender();
        }
    }

    function onFlightIconClick(latitude: number, longitude: number)
    {
        flyToLocation(latitude, longitude, 1000, props.cesiumViewerWrapper);
    };

    function flyToLocation(latitude: number, longitude: number, height: number, viewer: any) {

        viewer.camera.flyTo({
            destination: Cesium.Cartesian3.fromDegrees(
                longitude,
                latitude,
                height
            )
        });
    }

    function updateClassificationStyle(layer: I3DLayer, classificationClasses: I3DClassificationClasses[]) {
        // Generate the show expression
        var classDisplay = getShowForLayerByClassification(layer, classificationClasses);
        // Update the layer show expression
        layer.showExpression = classDisplay;

        // Update all the tiles
        if (layer.tiles !== undefined) {
            // Set the show attribute of each layer tile
            for (const tile of layer.tiles) {
                const tileset = tile.tilesetRef;
                tileset.style.show = classDisplay;

                // Only show non ground points 
                tileset.style = new Cesium.Cesium3DTileStyle({
                    color: {
                        conditions: layer.styleCondition,
                    },
                    pointSize: POINT_SIZE, // Point Size of 3d Tile point cloud
                    show: classDisplay,
                });
            }

            props.cesiumViewerWrapper.scene.requestRender(); // Force render of a new frame. Refer to https://cesium.com/blog/2018/01/24/cesium-scene-rendering-performance/
        }
    }

    const onClassSelectionChange = (classificationClasses: I3DClassificationClasses[]) =>{
        setClassificationClasses(classificationClasses); // Update the parent state for pop out state

        // Get the layer changed by the user
        const classificationClass = classificationClasses.find(c => c.hasChanged === true);
        try {
            if (classificationClass !== undefined) {
                const layerId = classificationClass.threeDTileLayerId;
                const layer = layerList.find(({ threeDTileLayerId }) => threeDTileLayerId === layerId);

                if (layer !== undefined) {
                    updateClassificationStyle(layer, classificationClasses);
                }
            }
        }
        catch (error) {
            console.error('Error on class selection change:', error);
        }
    };

    return (
        <Box>
            <div className={styles.threeDTileMenuIcon} style={{ top: topOffset + "px" }}>
                <IconButton onClick={onClickMenu}>
                    <ViewListIcon style={{ color: '#F7B500' }} />
                </IconButton>
            </div>
            {displayLayers && (
                <Box className={styles.threeDTileMenuContainer} style={{ top: (topOffset + 45) + "px" }}>
                    <Box className={styles.threeDTileMenuHeader}>
                        <p>3D LAYERS</p>
                    </Box>
                    {layerList.length > 0 ? (
                        <Box className={styles.threeDTileMenuBody}>
                            <a href="#" onClick={onSelectAll} className={styles.toggleAllLink}>
                                {allLayersVisible ? 'Deselect All' : 'Select All'}
                            </a>
                            <div>
                                <input type="checkbox" disabled={isLoading} style={{ marginTop: 6 }} checked={isSplitScreen} onClick={() => onSplitChange()} />
                                <span className={styles.splitter}>Split Screen</span>
                            </div>
                            <ul>
                                {layerList.map(layer => (
                                    <li key={layer.threeDTileLayerId}>
                                        <input type="checkbox" disabled={isLoading} style={{marginTop : 6}} checked={layer.defaultVisibility} onClick={() => onLayerChange(layer.threeDTileLayerId)} />
                                        <span className={styles.layerName}>{layer.name}</span>
                                        <IconButton
                                            onClick={() => onFlightIconClick(layer.latitude, layer.longitude)}
                                            aria-label="flight-takeoff"
                                            size="small"
                                        >
                                            <LocationSearchingIcon style={{fontSize : '16px', marginTop:'4px'}} />
                                        </IconButton>
                                    </li>
                                ))}
                            </ul>
                            <ClassificationList layers={layerList} onClassSelectionChange={onClassSelectionChange} classificationClasses={classificationClasses} isLoadingTiles={isLoading}  />
                        </Box>) : (
                            <Box className={styles.noLidarTileMenuBody}>
                                <p>No visible 3D layers</p>
                             </Box>
                        )}
                        
                </Box>
            )}
        </Box>
    );
};

export default LayerMenu;


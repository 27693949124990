import React, { useState, useEffect } from 'react';
import styles from './SegmentMenu.module.css';
import { Box, IconButton } from '@material-ui/core';
import ViewListIcon from '@material-ui/icons/ViewList';
import LocationSearchingIcon from '@material-ui/icons/LocationSearching';
import { CesiumViewerWrapper } from '../CesiumViewerWrapper/CesiumViewerWrapper';

interface ISegmentMenuProps {
    cesiumViewerWrapper: CesiumViewerWrapper;
    containerHeaderSize?: number;
    segmentId: number | null;
    segmentExternalReferenceId: number | null;
    model: any | null;
    onSegmentMenuChange: Function;
    segmentMenuPoppedOutState: any;
}


const SegmentMenu = (props: ISegmentMenuProps ) => {
    let Cesium = (window as any).Cesium;

    const [isSegmentModelVisible, setIsSegmentModelVisible] = useState(true);
    const [displaySegmentModels, setDisplaySegmentModels] = useState<boolean>(false);
    const [topOffset, setTopOffset] = useState(-23); 
    const [isSegmentMenuInitialised, setIsSegmentMenuInitialised] = useState<boolean>(false);

    useEffect(() => {
        setIsSegmentModelVisible(true);
    }, [props.segmentId]); 

    useEffect(() => {
        // Update the visibility from the pop out state after the segment menu is created.
        if (props.segmentMenuPoppedOutState !== null && props.segmentMenuPoppedOutState !== undefined && !isSegmentMenuInitialised) { // Only want to do this once to synch pop out state
            // segmentMenu updated from pop out state
            setIsSegmentModelVisible(props.segmentMenuPoppedOutState.visibility);
        }

        if (props.model) {
            props.model.show = isSegmentModelVisible;
            props.cesiumViewerWrapper.cesiumViewer.scene.requestRender();
            setIsSegmentMenuInitialised(true);
        }

        // Disabled Splitter
        const slider = document.getElementById("slider");
        if (slider != null) slider.style.display = 'none';
        
    
    }, [isSegmentMenuInitialised, isSegmentModelVisible, props.model, props.segmentMenuPoppedOutState]);

    useEffect(() => {
        if (props.containerHeaderSize) {
            setTopOffset(props.containerHeaderSize - 23);
        }
    }, [props.containerHeaderSize]);

    function onClickMenu() {
        setDisplaySegmentModels(!displaySegmentModels);
    }

    const toggleVisibility = () => {
        var visibility = !isSegmentModelVisible;
        setIsSegmentModelVisible(visibility);

        var popOutState = { segmentId: props.segmentId, segmentExternalReferenceId: props.segmentExternalReferenceId, visibility: visibility }
        props.onSegmentMenuChange(popOutState);
    };

    const zoomToModel = () => {
        if (props.model) {
            props.cesiumViewerWrapper.cesiumViewer.zoomTo(
                props.model,
                new Cesium.HeadingPitchRange(
                    0.0,
                    Cesium.Math.toRadians(-30),
                    props.model.boundingSphere.radius * 2.0
                )
            );
        }
    };

    return (
        <Box>
            <div className={styles.segmentMenuIcon} style={{ top: topOffset + "px" }}>
                <IconButton onClick={onClickMenu}>
                    <ViewListIcon style={{ color: '#F7B500' }} />
                </IconButton>
            </div>

            {displaySegmentModels && props.segmentId !== null && props.model && (
                <Box className={styles.segmentMenuContainer} style={{ top: (topOffset + 45) + "px" }}>
                    <Box className={styles.segmentMenuHeader}>
                        <p>ASSETS</p>
                    </Box>
                    <Box className={styles.segmentMenuBody}>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                            <input
                                type="checkbox"
                                checked={isSegmentModelVisible}
                                onChange={toggleVisibility}
                            />
                            <span className={styles.segmentName}>{props.segmentExternalReferenceId}</span>
                            <IconButton onClick={zoomToModel} size="small">
                                <LocationSearchingIcon style={{ fontSize: '16px' }} />
                            </IconButton>
                        </div>
                    </Box>

                </Box>
            )}
        </Box>
    );
};

export default SegmentMenu;

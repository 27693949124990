/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import { IThreeDViewerConfig } from '../../models/IThreeDViewerConfig';
import { IThreeDViewerState } from '../../models/IThreeDViewerState';
import { Toolbox } from './Measurement/ToolBox/Toolbox';
import { getAuthorizationData, refreshToken, getTokenIfValidAndNotExpired, fetchWithAuthorisationHeader } from "../../services/AuthenticationService";
import { IThreeDModel } from '../../models/IThreeDModel';
import { IGeneralSnackbarConfig } from '../../models/IGeneralSnackbarConfig';
import { GeneralSnackbar } from '../SnackBar/GeneralSnackbar/GeneralSnackbar';
import AssetMarker from './AssetMarker/AssetMarker';
import LayerMenu from './LayerMenu/LayerMenu';
import SegmentMenu from './SegmentMenu/SegmentMenu';
import MapControls from './MapControls/MapControls';
import Compass from './Compass/Compass';
import { IExtent } from '../../models/IExtent';
import axios from 'axios';
import { CesiumViewerWrapper } from './CesiumViewerWrapper/CesiumViewerWrapper';
import { IMapLayer } from '../../models/IMapLayer';
import { ICoordinate } from '../../models/ICoordinate';
import { IThreeDViewerFeature } from '../../models/IThreeDViewerFeature';
import { I3DLayer } from '../../models/I3DLayer';
import { ISegmentLayer } from '../../models/ISegmentLayer';
import { IMenuLayer } from '../../models/IMenuLayer';
import { I3DClassificationClasses } from '../../models/I3DClassification';

import styles from './ThreeDViewer.module.css';

// FYI any code that uses the cesiumViewerWrapper or cesiumViewer needs to be aware that the viewer can be
// reinitialised multiple times during the lifecycle of this component and maybe need to reinitialise when 
// this happens.

interface IProps {
    mapDataLayers: IMapLayer[][] | null; // Contains all the map layers including orthos, shape files and WMS layers
    flyToLocation: ICoordinate | null;
    config: IThreeDViewerConfig | null;
    getViewerStateFlag?: number;
    viewerState: IThreeDViewerState | null;
    onGetViewerState?: Function;
    cesiumToken: string;
    apiUrl: string;
    containerHeaderSize?: number;
    features?: IThreeDViewerFeature[] | null;
    onCameraMoveEnd?: Function;
    onFeatureSelected?: Function;    
    selectedFeatureDetailsElement?: JSX.Element | null;
    onLeftClick?: Function;
    renderFlag?: number;
}

interface ICesiumProviders {
    name: string;
    layer: any | null;
}
export function ThreeDViewer(props: IProps) {

    const [fullModelUrl, setFullModelUrl] = useState<string | null>(null);
    const [toolboxInitialiseFlag, setToolboxInitialiseFlag] = useState<number>(0);
    const [model, setModel] = useState<any>(null);
    const [tilesetUrl, setTilesetUrl] = useState<any>(null);
    const [generalSnackbarConfig, setGeneralSnackbarConfig] = useState<IGeneralSnackbarConfig | null>(null);
    const [selectionMode, setSelectionMode] = useState<boolean>(false);
    const [lastSegmentId, setLastSegmentId] = useState<number>(0);
    const [heightOffset, setHeightOffset] = useState<number>(0.0);
    const [ionAccess, setIonAccess] = useState<boolean | null>(null);
    const [cameraExtent, setCameraExtent] = useState<IExtent | null>(null);
    const [canLoad3DTiles, setCanLoad3DTiles] = useState<boolean>(false);
    const [terrainProvider, setTerrainProvider] = useState<any | null>(null);
    const [imageryProviders, setImageryProviders] = useState<ICesiumProviders[]>([]);
    const [cesiumViewerWrapper, setCesiumViewerWrapper] = useState<CesiumViewerWrapper>(new CesiumViewerWrapper());
    const [initialHeading, setInitialHeading] = useState<number>(0);
    const [initialPitch, setInitialPitch] = useState<number>(0);
    const [splitterPosition, setSplitterPosition] = useState<number>(0);
    const hadFirstMoveEnd = useRef(false);    
    const cesiumContainerRef = useRef(null);
    const [mousePosition, setMousePosition] = useState<{ x: number; y: number } | null>(null);
    const [viewerRotation, setViewerRotation] = useState(0);
    const selectedEntity = useRef(null);
    const [lastFeatures, setLastFeatures] = useState<IThreeDViewerFeature[] | null>(null);
    const [cesiumViewerInitialisedFlag, setCesiumViewerInitialisedFlag] = useState<number>(0);
    const [layerMenu, setLayerMenu] = useState<IMenuLayer[] | null>(null);
    const [segmentMenu, setSegmentMenu] = useState<ISegmentLayer | null>(null);
    const [classificationSelection, setClassificationSelection] = useState<I3DClassificationClasses[] | null>(null);
    const [layerMenuPoppedOutState, setLayerMenuPoppedOutState] = useState<I3DLayer[] | null>(null);
    const [segmentMenuPoppedOutState, setSegmentMenuPoppedOutState] = useState<ISegmentLayer | null>(null);
    const [classificationSelectionPoppedOutState, setClassificationSelectionPoppedOutState] = useState<I3DClassificationClasses[] | null>(null);
    const [showSelectedFeatureDetails,setShowSelectedFeatureDetails ] = useState<boolean>(false);
    const lastMouseLeftClickPosition = useRef(null);

    const CAMERA_HEIGHT = 1500;

    const toggleSelectionMode = (isOn: boolean) => {
        setSelectionMode(isOn);
    }

    // FYI This Cesium is a global object loaded via js file in index.html as there were compilation issues with Cesium from Npm.
    let Cesium = (window as any).Cesium;

    const CESIUM_TOKEN = props.cesiumToken;
    Cesium.Ion.defaultAccessToken = CESIUM_TOKEN;

    let geodesic = new Cesium.EllipsoidGeodesic();

    async function getModelsForSegment(segmentId: number): Promise<any> {

        let url = props.apiUrl + "threedmodel/models/" + segmentId;

        var response = await fetchWithAuthorisationHeader(url);
        return response.data as IThreeDModel[];
    }

    async function tryLoadModel(config: IThreeDViewerConfig, viewerState: IThreeDViewerState | null) {

        // We only care about popupState at the time the component gets popped out
        let popupState = viewerState != null && viewerState.segmentId === config.segmentId ? viewerState : null;
        console.log(popupState);

        let fileUrl = popupState != null ? popupState.fullModelUrl : null;
        let heightOffset = popupState != null ? popupState.heightOffset : 0.0;

        if (!popupState) {
            console.log("inside");
            // Does the segment have any models?
            let models = await getModelsForSegment(config.segmentId);

            if (models && models.length > 0) {
                // Load the first model which is the newest
                // We pass the model url (usually to tileset.json) to Cesium and then it will load tile files from that same path
                fileUrl = props.apiUrl + "threedmodel/modelfile" + (!models[0].path.startsWith('/') ? '/' : '') + models[0].path;
                heightOffset = models[0].heightOffset;
            }
        }
        console.log(fileUrl);

        setFullModelUrl(fileUrl);
        setHeightOffset(heightOffset);

        if (fileUrl) {

            loadModel(fileUrl, heightOffset, popupState);

        }
        else if (popupState) {
            // Component has been popped out and segment does not have any models
            // Fly to the the position that the user was looking at
            flyToPosition(popupState.cameraPosition, popupState.cameraHeading, popupState.cameraPitch);
        }
        else if (props.config != null) {
            flyToLocation(props.config.location.latitude, props.config.location.longitude, CAMERA_HEIGHT); // on complete of fly, should call the moveEnd event handler.                
        }
    }


    async function loadModel(modelUrl: string, heightOffset: number, popupState: IThreeDViewerState | null) {
        try {

            var authData = getAuthorizationData();

            // Load the model into Cesium.  Cesium will call our api to get files as needed
            // For authorisation it passes the access_token query string parameter

            // If the token expires during an api call we refresh it in the retryCallback function

            const resource = new Cesium.Resource({
                url: modelUrl,
                queryParameters: {
                    'access_token': authData.token
                },
                retryCallback: retryCallback,
                retryAttempts: 5
            })

            let tileset = await Cesium.Cesium3DTileset.fromUrl(resource, {
                shadows: Cesium.ShadowMode.DISABLED,
                preloadWhenHidden: true,
                maximumScreenSpaceError: 1, // Default 16
                //debugShowBoundingVolume: true,
                //debugShowContentBoundingVolume: true,
            });

            var tilesetEventListener = function () {
                let terrainHeight: number = 0;
                let autoHeightOffset: number = 0;

                console.log('All tile was loaded.');

                let modelCentre = Cesium.Cartographic.fromCartesian(tileset.boundingSphere.center);
                //let modelCentre = Cesium.Cartographic.fromDegrees(-111.6815456, 40.2655038);
                //terrainProvider	TerrainProvider	The terrain provider from which to query heights.
                //level	Number	The terrain level - of - detail from which to query terrain heights.
                //positions
                var positions = [
                    modelCentre,
                ];

                //  console.log('The supplied height is a height above the reference ellipsoid (such as Ellipsoid.WGS84) rather than an altitude above mean sea level. ');
                //  console.log('In other words, it will not necessarily be 0.0 if sampled in the ocean.');
                Cesium.sampleTerrainMostDetailed(cesiumViewerWrapper.terrainProvider, positions)
                    .then(function (samples: any) {
                        console.log('sampleTerrainMostDetailed Height in meters is: ' + positions[0].height);
                        terrainHeight = positions[0].height;

                        // Get Tileset height at point
                        //let tileHeight: number = tileset.getHeight(modelCentre, viewer.scene);
                        let tileHeight: number = getModelHeight(tileset, modelCentre, 5.0, 10, 2); // 2 - return lowest value
                        console.log('Tile.getHeight  ' + tileHeight);

                        autoHeightOffset = (terrainHeight - tileHeight);
                        console.log('Calcualted Height Offset ' + autoHeightOffset);

                        setModelHeightOffset(tileset, autoHeightOffset);

                        // Remove event so it doesn't continue to fire everytime you zoom in to the model
                        tileset.allTilesLoaded.removeEventListener(tilesetEventListener);

                    });

            }

            if (heightOffset === 0) {
                tileset.allTilesLoaded.addEventListener(tilesetEventListener);
            }
            else {
                console.log('Using height offset from DB ' + heightOffset);
                setModelHeightOffset(tileset, heightOffset);
            }


            setModel(cesiumViewerWrapper.addPrimitiveToScene(tileset));

            var flyToPositionEventListener = function () {
                if (popupState != null) {
                    flyToPosition(popupState.cameraPosition, popupState.cameraHeading, popupState.cameraPitch);
                    tileset.allTilesLoaded.removeEventListener(flyToPositionEventListener); // Ensure event only fires once. 
                }
            }

            if (popupState != null) {
                // The component has been popped out so restore the view the user was looking at
                tileset.allTilesLoaded.addEventListener(flyToPositionEventListener);
            }

            cesiumViewerWrapper.scene.globe.depthTestAgainstTerrain = true;

            // Set the camera to default view and then zoom to model so that the below headingPitch calculation will work consistently regardless of where the camera was previously.
            await setCesiumViewerDefalutView();

            cesiumViewerWrapper.zoomTo(
                tileset,
                new Cesium.HeadingPitchRange(
                    0.0,
                    -0.5,
                    tileset.boundingSphere.radius * 4.0
                )
            );

        } catch (error: any) {
            setGeneralSnackbarConfig({ messageType: 'error', message: 'Failed to load the model. Please try again or contact Support if the problem persists.', verticalAnchorOrigin: 'bottom', autoHideDuration: 5000 });
        }
    }

    function getCameraExtent(cesiumViewer: any) {
        const camera = cesiumViewer.camera;
        const rectangle = camera.computeViewRectangle(cesiumViewer.scene.globe.ellipsoid);
        if (rectangle) {
            let west = Cesium.Math.toDegrees(rectangle.west);
            let south = Cesium.Math.toDegrees(rectangle.south);
            let east = Cesium.Math.toDegrees(rectangle.east);
            let north = Cesium.Math.toDegrees(rectangle.north);

            console.log("extent:", {
                west,
                south,
                east,
                north
            });

            setCameraExtent({ west, south, east, north });
        }

        //calculate area
        const width = rectangle.east - rectangle.west;
        const height = rectangle.north - rectangle.south;
        const areaInSquareDegrees = width * height;
        console.log(`Approximate Area in Degrees: ${areaInSquareDegrees}`);


        // Convert width and height from degrees to radians 
        const widthInRadians = Cesium.Math.toRadians(width);
        const heightInRadians = Cesium.Math.toRadians(height);

        // Approximate the area using the radius of the Earth (in square kilometers) - Haversine formula 
        const earthRadius = 6371.0; // Earth's radius in kilometers
        const areaInSquareKilometers = earthRadius * earthRadius * widthInRadians * heightInRadians;
        console.log(`Approximate Area in Square Kilometers: ${areaInSquareKilometers} km�`);


        const cameraHeight = cesiumViewer.camera.positionCartographic.height; // represents zoom
        const cameraPitch = Cesium.Math.toDegrees(cesiumViewer.camera.pitch); //represents tilt - pitch close to 0 or 90 is horizon/sky. pitch close to -90 ideal(looking down)

        const desiredZoomLevel = 2000;

        const isWithinZoomLevel = cameraHeight <= desiredZoomLevel;
        const isLookingDown = !(cameraPitch < 20 && cameraPitch > -20);

        const canLoad3DTiles = isWithinZoomLevel && isLookingDown;
        setCanLoad3DTiles(canLoad3DTiles);

        console.log(`Camera Height: ${cameraHeight} meters`);
        console.log(`Camera Pitch: ${cameraPitch}�`);
        console.log(`Is looking down: ${isLookingDown}�`);
        console.log(`Can load model: ${canLoad3DTiles}`);
    }

    const retryCallback: any = async (resource: any, error: any) => {

        if (error?.statusCode === 401) {

            // Auth issue due to token expiry.  We *might* need to refresh the token
            // As we are potentially loading multiple tiles at the same time we can get a race condtion where they are all trying to refresh the token at almost the same time
            // So first check if we actually do have a valid token from a recent refresh

            var token = getTokenIfValidAndNotExpired();

            if (token == null) {
                // We dont have a valid token so do a refresh
                token = await refreshToken();
            }

            if (token != undefined && resource != undefined) {
                resource.setQueryParameters({ 'access_token': token })
                return true;
            }
        }
        return false
    }

    const resetGeneralSnackbarIonBased = () => {
        //prioratise ion error against any other error.
        if (ionAccess === false) {
            setGeneralSnackbarConfig({ messageType: 'error', message: 'No base map shown due to missing key. Please notify AIMS support.', verticalAnchorOrigin: 'bottom', autoHideDuration: 5000 });
        }
        else {
            setGeneralSnackbarConfig(null);
        }
    }

    useEffect(() => {
        const getProfile = async () => {
            await axios
                .get('https://api.cesium.com/v1/me', {
                    headers: { Authorization: 'Bearer ' + CESIUM_TOKEN }
                })
                .then(
                    (result) => {
                        if (result.status === 200) {
                            setIonAccess(true);
                        }
                    },
                    (error) => {
                        if (error.response.status === 401) {
                            setIonAccess(false);
                        }
                        else {
                            console.log(error.data);
                        }
                    }
                );
        };

        getProfile();
    }, []);

    useEffect(() => {
        // The config has changed

      
        if (props.config != null && model !== null) {
            // A model was previously loaded

            // We have found issues when you load model A then load model B that B does not zoom to the correct location and does not load properly
            // This only happens with certain big models e.g. asset 1001000 in Test

            // Destroy the current viewer, reset relevant variables, then construct a new viewer in initialiseViewer() so that this model will load ok.
            teardownCesiumViewer();
        }

        if (!cesiumViewerWrapper.hasViewer()) {
            
            // Create the Cesium viewer

            // NOTE The viewer can be initialised MULTIPLE times during the lifecycle of this component.  
            // Once when the component is created and other times when loading models.

            // If writing code that uses the viewer then you might need to make sure you reinitialise
            // when the viewer is reinitialised.  If using a component have a look at the cesiumViewerInitialisedFlag variable.

            initialiseCesiumViewer();

            selectedEntity.current = null;

            // Initialise map layers
            if (props.mapDataLayers != null) {
                // Draw map layers
                createMapLayers(props.mapDataLayers, cesiumViewerWrapper);
            } 
        }

        if (props.config != null) {
            
            // Config has changed

            // The config change has two use cases:
            //
            // A valid segmentId is passed. In this case we try and load a model for that segment.  This could be triggered by the VAA Segment details menu.
            // -1 is passed as the segmentId.  In this case we just fly to the supplied coordinate.  This could be triggered from the map.

            resetGeneralSnackbarIonBased();
            setLastSegmentId(props.config.segmentId);
            setToolboxInitialiseFlag(toolboxInitialiseFlag + 1);
            hadFirstMoveEnd.current = false;

            // Store the current terrainProvider so we can set use it if we reinitialise the viewer
            if (cesiumViewerWrapper.terrainProvider !== undefined) 
                setTerrainProvider(cesiumViewerWrapper.terrainProvider);

            setSegmentMenuPoppedOutState(props.viewerState?.segmentMenu);

            if (props.config.segmentId === -1) {
                // Handle launching Cesium from Map
                setCanLoad3DTiles(true); // Force load tiles after fly-To

                if (props.viewerState) {
                    // Note: Update the props from the poppedout_Viewer_State.
                    // Popped out into new window.  Restore the users position, heading and pitch
                    setSplitterPosition(props.viewerState.splitterPosition); // If splitter position is > 0, then splitter is enabled.
                    setLayerMenuPoppedOutState(props.viewerState.layerMenu);
                    setClassificationSelectionPoppedOutState(props.viewerState.classificationSelection);

                    flyToPosition(props.viewerState.cameraPosition, props.viewerState.cameraHeading, props.viewerState.cameraPitch);
                }
                else {
                    flyToLocation(props.config.location.latitude, props.config.location.longitude, CAMERA_HEIGHT); // on complete of fly, should call the moveEnd event handler.                
                }
            }
            else {
                // Handle Cesium launched from Segment (has segment Id)                                            
                tryLoadModel(props.config, props.viewerState);
            }
        }

        return () => {
            // this now gets called when the component unmounts
        };


    }, [props.config, cesiumViewerWrapper]);

    useEffect(() => {
        resetGeneralSnackbarIonBased();

        if (cesiumViewerWrapper.hasViewer() && props.onGetViewerState && props.getViewerStateFlag && props.getViewerStateFlag > 0 && props.config) {

            // Return the current viewer state so it can be popped out

            let heading = cesiumViewerWrapper.camera.heading;
            let pitch = cesiumViewerWrapper.camera.pitch;

            if (model === null) {
                // When the viewer is loaded without a model the values for pitch and heading are invalid until the user has moved 
                // the camera.  Return defaults unless the camera has been moved.

                // The default value for heading is Pi
                let tolerance = 0.00000001;
                if (Math.abs(heading - initialHeading) < tolerance)
                    heading = Cesium.Math.toRadians(0.0);

                if (pitch === initialPitch)
                    pitch = Cesium.Math.toRadians(-100.0);
            }

            console.log('onGetViewerState heading=' + heading + '   pitch=' + pitch + '  x=' + cesiumViewerWrapper.camera.position.x + '  y=' + cesiumViewerWrapper.camera.position.y + '  z=' + cesiumViewerWrapper.camera.position.z);

            props.onGetViewerState({
                segmentId: props.config.segmentId,
                cameraPitch: pitch,
                cameraHeading: heading,
                cameraPosition: cesiumViewerWrapper.camera.position,
                fullModelUrl: fullModelUrl,
                heightOffset: heightOffset,
                splitterPosition,
                layerMenu,
                segmentMenu,
                classificationSelection,
            });
        }

    }, [props.getViewerStateFlag, cesiumViewerWrapper, fullModelUrl, heightOffset]);

    useEffect(() => {

        if (props.mapDataLayers != null && imageryProviders.length > 0) {
            updateMapLayers(props.mapDataLayers, cesiumViewerWrapper);
        }
    }, [props.mapDataLayers, imageryProviders]);

    useEffect(() => {
        if (props.flyToLocation != null) {
            flyToLocation(props.flyToLocation.latitude, props.flyToLocation.longitude, CAMERA_HEIGHT);
        }
    }, [props.flyToLocation]);
    
    useEffect(() => {

        cesiumViewerWrapper.requestRender();

    }, [props.renderFlag]);

    useEffect(() => {

        setShowSelectedFeatureDetails(props.selectedFeatureDetailsElement != null);

    }, [props.selectedFeatureDetailsElement]);

   useEffect(() => {
        if (props.features == null) 
            return;

        // The features have changed
        // Work out diffs of what to add and what to remove based on what features are currently loaded
        let featuresToAdd: any[] = [];
        let featuresToRemove: any[] = [];
        let ids:any[] = props.features.map(f=>f.id);
        let updatedIds:any[] = props.features.filter((f:IThreeDViewerFeature) => isUpdatedFeature(f)).map((f:IThreeDViewerFeature)=>f.id);
        let existingIds:any[] = [];

        cesiumViewerWrapper.cesiumViewer.entities.values.forEach( (entity:any) => {

            if ( entity.isThreeDViewerFeature === true){
                if (ids.includes(entity.threeDViewerFeatureId) && !updatedIds.includes(entity.threeDViewerFeatureId))
                {
                    existingIds.push(entity.threeDViewerFeatureId);

                    // Update the label display based on its distance from the camera 
                    entity.label.show = showFeatureLabel( toDegrees( entity.position.getValue() ));
                }
                else
                {
                    featuresToRemove.push(entity);

                    // When removing an entity make sure its details are not showing
                    let lastSelectedEntity:any = selectedEntity.current;
                    if ( lastSelectedEntity != null && lastSelectedEntity.threeDViewerFeatureId === entity.threeDViewerFeatureId )
                        setShowSelectedFeatureDetails(false);
                }
            }
        });

        props.features.forEach( (feature:IThreeDViewerFeature) => {
                
            if (!existingIds.includes(feature.id))
                featuresToAdd.push(feature);
        });

        addFeatures( featuresToAdd );
        cesiumViewerWrapper.removeEntities( featuresToRemove );

        cesiumViewerWrapper.requestRender();

        setLastFeatures([...props.features]);
            
    }, [props.features]);

    // Destroy the Cesium viewer and reset relevant variables so they can be reinitialised
    function teardownCesiumViewer(){
        setImageryProviders([]);
        cesiumViewerWrapper.destroyViewer();
        setModel(null);
    }

    // Any code that depends on the viewer should be initialised here as the viewer can be initialised multiple times
    // during the lifetime of this component
    function initialiseCesiumViewer(){

        let theCesiumViewer = new Cesium.Viewer("cesiumContainer", {
            infoBox: false,
            selectionIndicator: false,
            shadows: false,
            shouldAnimate: true,
            animation: false,
            fullscreenButton: false,
            geocoder: false,
            timeline: false,
            homeButton: false,
            sceneModePicker: false,
            scene3DOnly: true, //saves memory
            creditContainer: "credits",
            creditViewport: "credits",
            terrain: Cesium.Terrain.fromWorldTerrain({
                requestWaterMask: true,
                requestVertexNormals: true,
            }),
            requestRenderMode: true, // https://cesium.com/blog/2018/01/24/cesium-scene-rendering-performance/
            maximumRenderTimeChange: Infinity,
            navigationHelpButton: true
        });

        // Store Viewer
        cesiumViewerWrapper.setCesiumViewer(theCesiumViewer);

        // Set a flag so we can signal to child components that the viewer has changed so they can reinitialise
        setCesiumViewerInitialisedFlag(cesiumViewerInitialisedFlag + 1);
        
        // When initialsing the cesium viewer when the component is created the terrainProvider is setup asynchronously which means for us when we re-initialise it is not set 
        // by the time it is used by tilesetEventListener in loadModel (its null).
        // The solution is get a reference to the one created on first load and then set on the viewer on subsequent re-initialiations.
        if (terrainProvider !== null) {
            theCesiumViewer.terrainProvider = terrainProvider;
        }

        cesiumViewerWrapper.scene.skyBox.show = false; // Hide the stars
        cesiumViewerWrapper.camera.percentageChanged = 0.1;

        // Set the default view to point north
        cesiumViewerWrapper.camera.setView({
            orientation: {
                heading: Cesium.Math.toRadians(0.0),
                roll: 0.0,
            }
        });

        //////////////// Setup Event Handlers 

        const handler = new Cesium.ScreenSpaceEventHandler(theCesiumViewer.scene.canvas);

        handler.setInputAction((movement: any) => {
            setMousePosition({
                x: movement.endPosition.x,
                y: movement.endPosition.y,
            });
        }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);
        
        // Register a camera move
        cesiumViewerWrapper.camera.moveEnd.addEventListener(() => {
            if (hadFirstMoveEnd.current === false) {
                // The first MoveEnd since the a model was loaded or a flyTo when just viewing a location 
                setInitialHeading(cesiumViewerWrapper.camera.heading);
                setInitialPitch(cesiumViewerWrapper.camera.pitch);

                hadFirstMoveEnd.current = true;
            }
                            
            if ( props.onCameraMoveEnd ){
                props.onCameraMoveEnd({ position: toDegrees( cesiumViewerWrapper.camera.position )});
            }
                
            getCameraExtent(theCesiumViewer);
        });
        
        // Handle camera changed
        cesiumViewerWrapper.camera.changed.addEventListener(() => {
            var deg = 360 - Math.round(Cesium.Math.toDegrees(cesiumViewerWrapper.camera.heading)); //

            if (deg === 180) { // 
                deg = initialHeading;
            }

            setViewerRotation(deg);
        })
            
        // Handle selected entity changes
        theCesiumViewer.selectedEntityChanged.addEventListener((entity:any) => {
                
            if (props.onFeatureSelected === undefined) 
                return;
                    
            let lastSelectedEntity:any = selectedEntity.current;

            if ( entity != null && entity.isThreeDViewerFeature )
            {
                // Store old color values so they can be restored
                entity.label.lastBackgroundColor = entity.label.backgroundColor;
                entity.label.lastFillColor = entity.label.fillColor;
                entity.label.lastOutlineColor = entity.label.outlineColor;

                // Style the label to highlight the entity
                entity.label.backgroundColor = Cesium.Color.BLACK;
                entity.label.fillColor = Cesium.Color.YELLOW;
                entity.label.outlineColor = Cesium.Color.YELLOW;
                entity.label.eyeOffset = new Cesium.Cartesian3(0, 0, -5);

                if ( lastSelectedEntity == null || lastSelectedEntity.threeDViewerFeatureId != entity.threeDViewerFeatureId )
                    props.onFeatureSelected( entity.threeDViewerFeatureId, entity );
            }
                
            if ( lastSelectedEntity && lastSelectedEntity.isThreeDViewerFeature )
            {
                // Restore the last selected entities colours
                lastSelectedEntity.label.backgroundColor = lastSelectedEntity.label.lastBackgroundColor;
                lastSelectedEntity.label.fillColor = lastSelectedEntity.label.lastFillColor;
                lastSelectedEntity.label.outlineColor = lastSelectedEntity.label.lastOutlineColor;
                lastSelectedEntity.label.eyeOffset = null;
            }

            selectedEntity.current = entity;

            cesiumViewerWrapper.requestRender()
        });   
      
       //Add Left click handlers
       handler.setInputAction((click: any) => {

           // Store last mouse click so we can ignore drags
           lastMouseLeftClickPosition.current = click.position;

       }, Cesium.ScreenSpaceEventType.LEFT_DOWN);

       handler.setInputAction((click: any) => {
             
            if (props.onLeftClick === undefined)  
                return;            
            
            let lastClick = lastMouseLeftClickPosition.current as any;

            if ( lastClick != null && ( lastClick.x !== click.position.x || lastClick.y !== click.position.y ))
                return;

            let cartesianPoint = cesiumViewerWrapper.scene.pickPosition(click.position);
            if (Cesium.defined(cartesianPoint)) {
              let geoPoint = Cesium.Cartographic.fromCartesian(cartesianPoint);

              let clickPoint: any = toDegrees(cartesianPoint);

              clickPoint.height = geoPoint.height;

              props.onLeftClick(clickPoint);

            }

       }, Cesium.ScreenSpaceEventType.LEFT_UP);
    }

    function isUpdatedFeature(feature: IThreeDViewerFeature)
    {
        if ( lastFeatures === null )
            return false;

        let lastFeature = lastFeatures.find((oldFeature) => oldFeature.id === feature.id );
        if ( !lastFeature)
            return false;

        // Has the feature changed?
        return ( feature.name !== lastFeature.name || 
                feature.latitude !== lastFeature.latitude || 
                feature.longitude !== lastFeature.longitude ||
                feature.height !== lastFeature.height ||
                feature.description !== lastFeature.description ||
                feature.iconPath !== lastFeature.iconPath ||
                feature.colour !== lastFeature.colour 
        )
    }

    function addFeatures(features: IThreeDViewerFeature[]) {
        const defaultHeightOffset = 3; // An offset height to add to the features to make sure they are all above ground

        // Push all the features in to an array to get the terrain height all in one go.
        var positions:any = [];
        for (let f of features) {
            positions.push(Cesium.Cartographic.fromDegrees(f.longitude, f.latitude));
        }

        // Sample all the terrain height for all features in one go
        let featureCount = 0;
        Cesium.sampleTerrainMostDetailed(cesiumViewerWrapper.terrainProvider, positions)
            .then(function (samples: any) {
                for (let feature of features) {
                    let featureCalculatedHeight = positions[featureCount].height;

                    let featureHeight = feature.height !== 0 ? feature.height : featureCalculatedHeight + defaultHeightOffset;

                    // Only show labels near the camera
                    let showLabel = showFeatureLabel(new Cesium.Cartographic(feature.longitude, feature.latitude, featureHeight));

                    let entity = cesiumViewerWrapper.addEntity({
                        isThreeDViewerFeature: true,
                        threeDViewerFeatureId: feature.id,
                        description: feature.description,
                        name: feature.name,
                        position: new Cesium.Cartesian3.fromDegrees(feature.longitude, feature.latitude, featureHeight),
                        billboard: {
                            image: feature.iconPath,
                            verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
                            width: 20,
                            height: 20,
                        },
                        label: {
                            show: showLabel,
                            showBackground: true,
                            text: feature.name,
                            font: "14px Lato",
                            fillColor: Cesium.Color.fromCssColorString(feature.colour),
                            pixelOffset: new Cesium.Cartesian2(60.0, -36.0),
                            backgroundColor: new Cesium.Color(1, 1, 1, 0.4),
                            outlineColor: Cesium.Color.fromCssColorString(feature.colour),
                            outlineWidth: 2,
                            style: Cesium.LabelStyle.FILL_AND_OUTLINE
                        }
                    }, false); // Dont render as we are adding in bulk

                    let lastSelectedEntity: any = selectedEntity.current;
                    if (lastSelectedEntity && lastSelectedEntity.threeDViewerFeatureId === feature.id) {
                        cesiumViewerWrapper.cesiumViewer.selectedEntity = entity;
                    }

                    featureCount++;
                }

            });
    }

    function showFeatureLabel(cartographicPos:any):boolean
    {
        // Only show labels near the camera
        let maxDistanceFromCamera = 30000;

        let distanceFromCamera = getHorizontalDistance( toDegrees( cesiumViewerWrapper.camera.position ), cartographicPos );

        return distanceFromCamera < maxDistanceFromCamera ;
    }    

    /**
     * Create map layers in Cesium from the map layer state object. 
     * Cesium display order is based on the index of the collection. I.e. The last added layer will display above existing ones in the collection
     * @param mapLayers Layers from container
     * @param cesiumViewerWrapper Cesium viewer
     * @returns None
     */
    function createMapLayers(mapLayers: IMapLayer[][], cesiumViewerWrapper: CesiumViewerWrapper) {

        //const imageryProviders : ICesiumProviders[] = [];
        if (mapLayers != null) {
            // Sort based on zOrder. In Cesium, layer order is based on the order of the layer in the collection. 
            // For layers on the bottom, we want to add them first.
            mapLayers.sort((a, b) => a[0].zOrder - b[0].zOrder);
            
            //load mapserver layers
            for (var i = 0; i < mapLayers.length; ++i) {
                for (var j = 0; j < mapLayers[i].length; ++j) {
                    let mapDataLayer = mapLayers[i][j];
                    if (mapDataLayer.isCesium) { // Only add cesium layers
                        let provider = imageryProviders.find(p => p != null && p.name === mapDataLayer.label);
                        if (provider === null || provider === undefined) { // Only add if it doesn't already exist
                            createMapLayerProvider(mapDataLayer);
                        }
                    }
                }
            }

            setImageryProviders(imageryProviders);
        }
    }

    function createMapLayerProvider(mapDataLayer: IMapLayer) { 
        var authData = getAuthorizationData();
        if (mapDataLayer.group === 'OrthoLayer') {
            try {
                if (mapDataLayer.url != null) {
                    const orthoLayerResource = new Cesium.Resource({
                        url: props.apiUrl + mapDataLayer.url.replace("-y", "reverseY").replace("ortho/", "ortho/tile/"), // difference between openlayers and cesium
                        queryParameters: {
                            'access_token': authData.token
                        },
                        retryCallback: retryCallback,
                        retryAttempts: 5
                    })

                    var orthoLayerProvider = new Cesium.TileMapServiceImageryProvider(
                        orthoLayerResource,
                        {
                            tileWidth: 256,
                            tileHeight: 256
                        }
                    );

                    const layer = new Cesium.ImageryLayer(orthoLayerProvider, { minimumTerrainLevel: 13 }); // Turn on at level 13
                    layer.show = mapDataLayer.isActive;
                    cesiumViewerWrapper.cesiumViewer.imageryLayers.add(layer, mapDataLayer.zOrder); // Add based on the zindex order. The smaller the number, the lower it draws
                    imageryProviders.push({ name: mapDataLayer.label, layer: layer });
                }
            } catch (e) {
                console.log('ERROR,Could not add orthographic Layer ' + mapDataLayer.label + ': ' + e);
                return;
            }
        }
        else {
            try {
                const segmentLayerResource = new Cesium.Resource({
                    url: props.apiUrl + 'map/wms',
                    queryParameters: {
                        'access_token': authData.token
                    },
                    retryCallback: retryCallback,
                    retryAttempts: 5
                })

                var wmsLayerProvider = new Cesium.WebMapServiceImageryProvider({
                    url: segmentLayerResource,
                    parameters: {
                        transparent: 'true',
                        format: 'image/png',
                        version: '1.3.0'
                    },
                    layers: mapDataLayer.group,
                    enablePickFeatures: false,
                    tileMatrixSetID: 'default028mm',
                    tileWidth: 1024,
                    tileHeight: 1024
                });

                // https://github.com/CesiumGS/cesium/issues/6564
                const layer = new Cesium.ImageryLayer(wmsLayerProvider, { minimumTerrainLevel: 13 });
                layer.show = mapDataLayer.isActive;
                cesiumViewerWrapper.cesiumViewer.imageryLayers.add(layer, mapDataLayer.zOrder); // Add based on the zindex order. The smaller the number, the lower it draws
                imageryProviders.push({ name: mapDataLayer.label, layer: layer });
            } catch (e) {
                console.log("ERROR,Could not add mapserver Layer " + mapDataLayer.group + ": " + e);
                return;
            }
        }
    }

    /**
     * Update map layers in Cesium from the map layer state object. 
     * @param mapLayers Layers from container
     * @param cesiumViewerWrapper Cesium viewer
     * @returns None
     */
    function updateMapLayers(mapLayers: IMapLayer[][], cesiumViewerWrapper: CesiumViewerWrapper) {
        mapLayers.sort((a, b) => a[0].zOrder - b[0].zOrder);

        //load mapserver layers
        for (var i = 0; i < mapLayers.length; ++i) {
            for (var j = 0; j < mapLayers[i].length; ++j) {
                let mapDataLayer = mapLayers[i][j];

                if (mapDataLayer.isCesium) { // Only add cesium layers
                    let provider = imageryProviders.find(p => p != null && p.name === mapDataLayer.label);

                    if (provider != undefined) {
                        provider.layer.show = mapDataLayer.isActive;
                        cesiumViewerWrapper.cesiumViewer.scene.requestRender();
                    }
                    else {
                        createMapLayerProvider(mapDataLayer);
                    }

                }
            }
        }

        cesiumViewerWrapper.cesiumViewer.scene.requestRender();
    }

    /**
     * Return the lowest of the point heights or the average of the point height.
     * @param tileset The Cesium tileset
     * @param center The tileset centre
     * @param radius The radiun to generate random point
     * @param count The number of iterations
     * @param type 1 = average, 2 = lowest
     * @returns The lowest height or the average height
     */
    function getModelHeight(tileset: any, center: any, radius: number, count: number, type: number) {
        var sumOfHeight: number = 0;
        var iterations: number = 0;
        var lowestHeight: number = 0;
        for (var i = 0; i < count; i++) {
            var randomPoint = generateRandomPoint({ lat: center.latitude / Math.PI * 180, lng: center.longitude / Math.PI * 180 }, radius);
            var pointHeight = tileset.getHeight(Cesium.Cartographic.fromDegrees(randomPoint.lng, randomPoint.lat), cesiumViewerWrapper.scene);
            if (!isNaN(pointHeight)) {
                iterations++;
                sumOfHeight = sumOfHeight + pointHeight;

                if (lowestHeight === 0 || pointHeight < lowestHeight) lowestHeight = pointHeight;
            }
        }

        if (type === 1) return sumOfHeight / iterations;
        return lowestHeight;
    }

    /**
    * Generates number of random geolocation points given a center and a radius.
    * Reference URL: http://goo.gl/KWcPE.
    * @param  {Object} center A JS object with lat and lng attributes.
    * @param  {number} radius Radius in meters.
    * @return {Object} The generated random points as JS object with lat and lng attributes.
    */
    function generateRandomPoint(center: any, radius: number) {
        var x0 = center.lng;
        var y0 = center.lat;
        // Convert Radius from meters to degrees.
        var rd = radius / 111300;

        var u = Math.random();
        var v = Math.random();

        var w = rd * Math.sqrt(u);
        var t = 2 * Math.PI * v;
        var x = w * Math.cos(t);
        var y = w * Math.sin(t);

        var xp = x / Math.cos(y0);

        // Resulting point.
        return { 'lat': y + y0, 'lng': xp + x0 };
    }

    function toDegrees(cartesian3Pos:any) {
        let pos = Cesium.Cartographic.fromCartesian(cartesian3Pos)
        return {longitude: pos.longitude / Math.PI * 180,latitude:pos.latitude / Math.PI * 180};
    }

    function getHorizontalDistance(point1: any, point2: any) {
        geodesic.setEndPoints(point1, point2);
        return geodesic.surfaceDistance;
    }
    
    function flyToLocation(latitude: number, longitude: number, height: number) {

        cesiumViewerWrapper.camera.flyTo({
            destination: Cesium.Cartesian3.fromDegrees(
                longitude,
                latitude,
                height
            )
        });
    }

    function flyToPosition(cameraPosition: any, cameraHeading: any, cameraPitch: any) {

        cesiumViewerWrapper.camera.flyTo({
            destination: cameraPosition,
            orientation: {
                heading: cameraHeading,
                pitch: cameraPitch,
                roll: 0.0,
            }
        });
    }

    // Offset the 3d tileset height
    function setModelHeightOffset(tileset: any, heightOffset: number) {
        const cartographic = Cesium.Cartographic.fromCartesian(
            tileset.boundingSphere.center
        );
        const surface = Cesium.Cartesian3.fromRadians(
            cartographic.longitude,
            cartographic.latitude,
            0.0
        );
        const offset = Cesium.Cartesian3.fromRadians(
            cartographic.longitude,
            cartographic.latitude,
            heightOffset
        );
        const translation = Cesium.Cartesian3.subtract(
            offset,
            surface,
            new Cesium.Cartesian3()
        );
        tileset.modelMatrix = Cesium.Matrix4.fromTranslation(translation);
    }

    async function setCesiumViewerDefalutView() {

        cesiumViewerWrapper.cesiumViewer.camera.setView({
            destination: Cesium.Cartesian3.fromDegrees(
                0.0, // Longitude
                0.0, // Latitude
                30000000.0 // Height in meters (far enough to view most of the Earth)
            ),
            orientation: {
                heading: Cesium.Math.toRadians(0.0), // Facing north
                pitch: Cesium.Math.toRadians(-45.0), // Tilted down
                roll: 0.0
            }
        });
    }

    let sliderHeight = cesiumContainerRef.current != null ? (cesiumContainerRef.current as any).clientHeight : 0;
    let sliderTop = cesiumContainerRef.current != null ? (cesiumContainerRef.current as any).offsetTop : 0;

    const onSplitterToggleChange = (splitter: number) => {
        setSplitterPosition(splitter); // Set the splitter state to pass onto the container for pop out synching
    }

    const onLayerMenuChange = (menuLayers: IMenuLayer[]) => {     
        setLayerMenu(menuLayers);
    }

    const onSegmentMenuChange = (segmentLayer: ISegmentLayer) => {
        setSegmentMenu(segmentLayer);
    }

    const onClassificationSelectionChange = (classifications: I3DClassificationClasses[]) => {
        setClassificationSelection(classifications);
    }

    return (<div style={{ height: '100%', width: '100%' }}>
        <div id="cesiumContainer" style={{ height: '100%', width: '100%' }} ref={cesiumContainerRef} >
            {cesiumViewerWrapper.hasViewer() && <AssetMarker isOpenInPopup={props.viewerState != null} cesiumViewerWrapper={cesiumViewerWrapper} isSelectionModeOn={selectionMode} segmentId={props.config?.segmentId} />}
            <div id="slider" style={{ height: sliderHeight, marginTop: sliderTop }} className={styles.slider}></div>
        </div>
        <GeneralSnackbar config={generalSnackbarConfig} />

        <div>
            <Compass cesiumViewerWrapper={cesiumViewerWrapper} viewerRotation={viewerRotation} containerHeaderSize={props.containerHeaderSize} />
        </div>

        { showSelectedFeatureDetails && props.selectedFeatureDetailsElement &&
            <div className={styles.selectedFeatureDetailsContainer}>
                {props.selectedFeatureDetailsElement}
           </div>
        }

        <div id="LayerMenu" style={{ position: 'absolute', top: '20px', left: '5px' }}>
            {props.config?.segmentId === -1 ? (
                <LayerMenu
                    cesiumViewerWrapper={cesiumViewerWrapper}
                    cameraExtent={cameraExtent}
                    canLoad3DTiles={canLoad3DTiles}
                    apiUrl={props.apiUrl}
                    splitterPosition={splitterPosition}
                    layerMenu={layerMenuPoppedOutState} // Set for pop out use. layerMenuPoppedOutState is null unless popped out.
                    classificationSelection={classificationSelectionPoppedOutState} // Set for pop out use
                    onSplitterToggleChange={onSplitterToggleChange}
                    onLayerMenuChange={onLayerMenuChange}
                    onClassificationSelectionChange={onClassificationSelectionChange}
                    containerHeaderSize={props.containerHeaderSize}
                 />) : (
                <SegmentMenu
                        cesiumViewerWrapper={cesiumViewerWrapper}
                        segmentId={props.config ? props.config.segmentId : null}
                        segmentExternalReferenceId={props.config ? props.config.segmentExternalReferenceId : null}
                        model={model}
                        containerHeaderSize={props.containerHeaderSize}
                        onSegmentMenuChange={onSegmentMenuChange}
                        segmentMenuPoppedOutState={segmentMenuPoppedOutState }
               />)}
        </div>

        <div id="measurementToolbox" style={{ position: 'absolute', bottom: '10px', left: '10px' }}>
            <Toolbox cesiumViewerWrapper={cesiumViewerWrapper} initialiseFlag={toolboxInitialiseFlag} toggleSelectionMode={toggleSelectionMode} model={model} ></Toolbox>
        </div>
        <div id="credits" style={{ display: 'none', height: 0, width: 0 }}>div</div>

        <MapControls cesiumViewerWrapper={cesiumViewerWrapper} mousePosition={mousePosition} cesiumViewerInitialisedFlag={cesiumViewerInitialisedFlag} />

    </div>);
}
